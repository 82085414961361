.menu {
    position: absolute;
    background: aliceblue;
    /* height: 100%; */
    text-align: center;

}

.menu-image {
    width: 100%;
    /* height: auto;
    z-index: 10;
    position: relative;
    display: flex; */
    /* margin-top: 17%; */
    /* border-radius: 50%; */
}


.top {
   margin: 0px;
   padding: 0px;
}

.menu-name {
    background-color:  #e6f2ff;
    font-size: 22px;
    text-align: center;
}

.profile-container {
    height: 1000px;
    padding: 50px;
    display: flex;
}

.profile-left {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 700px;

}

.profile-right {
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    margin-right: 50px;
    margin-top: 0px;
}

.profile-right p {
    font-size: 30px;
    text-align: justify;
    margin: 10px;
}

.picture-container {
    padding: 0px;
    background-color:  #e6f2ff;
    text-align: center;
}

.picture {
    border-radius: 50%;
    width: 10%;
}