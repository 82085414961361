body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
}

#root {
  height: 100%;
}
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.menu {
    position: absolute;
    background: aliceblue;
    /* height: 100%; */
    text-align: center;

}

.menu-image {
    width: 100%;
    /* height: auto;
    z-index: 10;
    position: relative;
    display: flex; */
    /* margin-top: 17%; */
    /* border-radius: 50%; */
}


.top {
   margin: 0px;
   padding: 0px;
}

.menu-name {
    background-color:  #e6f2ff;
    font-size: 22px;
    text-align: center;
}

.profile-container {
    height: 1000px;
    padding: 50px;
    display: -webkit-flex;
    display: flex;
}

.profile-left {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    width: 700px;

}

.profile-right {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-left: 100px;
    margin-right: 50px;
    margin-top: 0px;
}

.profile-right p {
    font-size: 30px;
    text-align: justify;
    margin: 10px;
}

.picture-container {
    padding: 0px;
    background-color:  #e6f2ff;
    text-align: center;
}

.picture {
    border-radius: 50%;
    width: 10%;
}
.header {
    height: 5%;
    width: 100%;
    background-color: royalblue;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
    color: white;
    font-size: 27px;
}

.header-items {

}
.homepage {
    height: 100%;
    display: -webkit-flex;
    display: flex;
}

.hidden {
    display: none;
}
.paragraph {
    margin-left: 100px;
    margin-right: 50px;
    margin-top: 50px;
}

