.header {
    height: 5%;
    width: 100%;
    background-color: royalblue;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    color: white;
    font-size: 27px;
}

.header-items {

}